<template>
  <div class="addnoticelist">
    <div class="componentSty_content">
      <div class="addnoticelist_content">
        <div class="formcont">
          <el-form
            :model="form"
            :rules="rules"
            ref="forms"
            size="small"
            :show-message="false"
            label-width="60px"
          >
            <div class="lineitem">
              <el-form-item label="分类" prop="noticeType">
                <el-select v-model="form.noticeType" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in enumNoticeType"
                    :key="item.id"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="lineitem">
              <el-form-item label="等级" prop="noticeLevel">
                <el-select v-model="form.noticeLevel" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in enumNoticeLevel"
                    :key="item.id"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="lineitem">
              <el-form-item label="标题" prop="noticeTitle">
                <el-input
                  type="text"
                  v-model="form.noticeTitle"
                  placeholder="请输入标题（20字以内）"
                />
              </el-form-item>
            </div>
            <div class="textarte">
              <quillEditor
                v-model="form.noticeContext"
                :options="editorOption"
                style="width: 100%; height: 100%"
              ></quillEditor>
            </div>
          </el-form>
        </div>
        <div class="bottombtns">
          <div class="btn">
            <button style="background: #f3f4f5; color: #838aa9" @click="goBack">
              返回
            </button>
            <button
              style="margin-left: 30px"
              @click.stop="handleConfirmUpdate"
              v-if="isEdit"
            >
              确定
            </button>
            <button
              style="margin-left: 30px"
              @click.stop="handleConfirmAdd"
              v-else
            >
              确定
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
export default {
  name: "addNoticeList",
  data() {
    let that = this;
    let ipt = null;
    return {
      enumNoticeType: {
        1: "系统公告",
        2: "车队公告",
        3: "司机公告",
      },
      enumNoticeLevel: {
        1: "红色公告",
        2: "橙色公告",
        3: "黄色公告",
      },
      isEdit: false,
      form: {
        noticeContext: "", //公告内容
        noticeTitle: "", //公告标题
        noticeType: "", //公告类型
        noticeLevel: "", //等级
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline"], // toggled buttons
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ align: [] }, "image"],
            ],
            handlers: {
              image: function () {
                if (!ipt) {
                  ipt = document.createElement("input");
                  ipt.setAttribute("type", "file");
                  ipt.setAttribute(
                    "accept",
                    "image/png, image/gif, image/jpeg,image/jpg"
                  );
                  ipt.addEventListener("change", async (_) => {
                    let isok =
                      !/\/+(jpg|jpeg|png|gif)+$/gi.test(ipt.files[0].type) &&
                      that.$message.error("请上传正确格式的图片");
                    isok =
                      !(ipt.files[0].size / (1024 * 1024) < 5) &&
                      that.$message.error("上传的图片得小于5M");
                    if (isok) return "";
                    const formData = new FormData();
                    formData.append("multipartFile", ipt.files[0]);
                    const { data } = await that.$http(
                      "api1",
                      "/exchange/aliyun/uploadFile",
                      "post",
                      formData
                    );
                    if (data.code != 0)
                      return that.$message.error("上传图片失败");
                    let length = this.quill.getSelection(true).index;
                    this.quill.insertEmbed(length, "image", data.msg);
                    this.quill.setSelection(length + 1);
                  });
                }
                ipt.click();
              },
            },
          },
        },
      },
      rules: {
        noticeType: [{ required: true, trigger: "change" }],
        noticeLevel: [{ required: true, trigger: "change" }],
        noticeTitle: [
          { required: true, trigger: "blur" },
          { min: 1, max: 20, trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["usergt", "navigationMsgGt"]),
  },
  created() {
    if (this.$route.query.id) {
      this.isEdit = true;
      this.$http(
        "api1",
        "/api/biz/NoticeInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        for (let key in this.form) {
          this.form[key] = res.data.data[key];
        }
      });
    }
  },
  components: {
    mTitle,
    quillEditor,
  },
  methods: {
    goBack() {
      this.$router.push("/notice/noticeList");
    },
    // 获取公告等级
    /*newsnotice() {
      this.form.noticeContext &&
        (this.form.noticeContext = this.form.noticeContext.replace(
          /\<img/gi,
          "<img style='width:100%';height:100%'"
        ));
      switch (this.modules) {
        case "add":
          this.addinfomsg();
          break;
        case "edit":
          this.editinfo();
          break;
      }
    },*/
    async handleConfirmAdd() {
      /*let isok = !this.form.noticeType && this.$message.error("请选择公告类别");
      isok =
        isok ||
        (!this.form.noticeLevel && this.$message.error("请选择公告等级"));
      isok =
        isok || (!this.form.noticeTitle && this.$message.error("请填写标题"));
      if (isok) return "";*/
      const { data } = await this.$http(
        "api1",
        "/api/biz/NoticeInfo/save",
        "post",
        this.form
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.$message.success("添加成功");
      this.goBack();
    },
    async handleConfirmUpdate() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/NoticeInfo/update",
        "post",
        { id: this.$route.query.id, ...this.form }
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.$message.success("修改成功");
      this.goBack();
    },
    /*async editinfo() {
      let isok = !this.form.noticeType && this.$message.error("请选择公告类别");
      isok =
        isok || (!this.form.noticeTitle && this.$message.error("请填写标题"));
      if (isok) return "";
      this.form.gmtModified = new Date().getTime();
      const { data } = await this.$http(
        "api1",
        "/notice/message/update",
        "post",
        this.form
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.$message.success("修改成功");
      this.goBack();
    },*/
  },
};
</script>
<style lang="scss" scoped>
.addnoticelist {
  position: relative;
  height: 100%;
  .componentSty_content {
    position: absolute;
    top: 30px;
    bottom: 0;
    width: 100%;
    background: #fff;
  }
  .addnoticelist_content {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background: #ffffff;
    border-radius: 2px;
    padding: 2%;
    .formcont {
      height: 95%;
      width: 100%;
      .lineitem {
        height: 10%;
        .lineitemipt {
          box-sizing: border-box;
          padding: 0;
          height: 30px;
          outline: none;
          border: none;
          width: 420px;
          background: #f3f4f5;
          padding-left: 10px;
          border-radius: 5px;
          font-size: 60%;
        }
      }
      .textarte {
        height: 500px;
        width: 100%;
        .textareaipt {
          float: left;
          width: 90%;
          height: 80%;
          background: #f3f4f5;
          border: none;
          resize: none;
          outline: none;
          border-radius: 5px;
          font-size: 60%;
        }
      }
    }
    .bottombtns {
      margin-top: 20px;
      width: 100%;
      .btn {
        float: right;
        margin-right: 8%;
        button {
          width: 80px;
          height: 30px;
          background: #2b56bf;
          border-radius: 4px;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
</style>
